export type ProductWithSizes = Pick<Product,
  | 'breedte'
  | 'breedte_2'
  | 'eenheid_breedte'
  | 'eenheid_lengtehoogte'
  | 'eenheid_hoogtedikte'
  | 'hoogtedikte'
  | 'lengtehoogte'
>;

export function useProductSize(prod: MaybeRefOrGetter<ProductWithSizes>) {
  const product = toRef(prod);

  const defaultUnit = 'cm';
  const sizes = [];
  const sizesWithUnit = [];
  const units = [];

  if (product.value.lengtehoogte) {
    const unit = product.value.eenheid_lengtehoogte || defaultUnit;
    sizes.push(product.value.lengtehoogte);
    sizesWithUnit.push(product.value.lengtehoogte + ' ' + unit);
    units.push(unit);
  }

  if (product.value.breedte) {
    const unit = product.value.eenheid_breedte || defaultUnit;
    const width = product.value.breedte + (product.value.breedte_2 ? ('/' + product.value.breedte_2) : '');
    sizes.push(width);

    sizesWithUnit.push(width + ' ' + unit);

    units.push(unit);
  }

  if (product.value.hoogtedikte) {
    const unit = product.value.eenheid_hoogtedikte || defaultUnit;
    sizes.push(product.value.hoogtedikte);
    sizesWithUnit.push(product.value.hoogtedikte + ' ' + unit);
    units.push(unit);
  }

  if (sizes.length === 0) {
    return '';
  }

  const nrOfUnits = [...new Set(units)].length;

  if (nrOfUnits > 1) {
    return sizesWithUnit.join(' x ');
  }

  return sizes.join(' x ') + ' ' + units[0];
}
